import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import { tagLocales, categoryLocales } from '../locales/locales';
import { dateSort } from '../utils/sort';
import '../scss/index.scss';

export const query = graphql`
  {
    prismic {
      allArticles {
        edges {
          node {
            updated_at
            posted_at
            article_title
            _meta {
              uid
              tags
              lang
            }
            categories {
              category {
                ... on PRISMIC_Category {
                  name
                  _linkType
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Body = styled.div`
  margin: 0;
  display: flex;
  flex-flow: column;
  min-height: 100vh;
`;

const Section = styled.section`
  margin: 20px auto 60px auto;
  padding: 0 20px;
  max-width: 680px;
`;

const Articles = props => {
  const removeCategories = props.location.pathname.replace('/categories/', '');
  const pathType = removeCategories.replace('/', '');
  const pathTitle = categoryLocales[pathType];
  const articles = props.data.prismic.allArticles.edges;
  const dateSortArticle = articles.sort(dateSort());
  const filteredArticles = dateSortArticle.filter(d => {
    return (
      !!d.node.categories[0].category &&
      d.node.categories[0].category.name === pathTitle
    );
  });
  return (
    <Body>
      <SEO
        title={pathTitle}
        url={props.location.href}
        description={`${pathTitle}カテゴリーについての記事一覧ページです`}
      />
      <Layout>
        <Section>
          <div className="articles_index">
            <h1>{pathTitle}</h1>
            <ul>
              {filteredArticles.map(article => {
                const articleTitle = article.node.article_title[0].text;
                const link = article.node._meta.uid;
                const postedAt = article.node.posted_at;
                const updatedAt = article.node.updated_at;
                const tags = article.node._meta.tags;
                return (
                  <li key={link}>
                    <div className="article_title">
                      <Link to={`/${link}/`}>{articleTitle}</Link>
                    </div>
                    <div className="day">
                      <span className="posted_at">{postedAt}</span>
                      {!!updatedAt && (
                        <span className="updated_at">更新:{updatedAt}</span>
                      )}
                    </div>
                    {tags.length > 0 &&
                      tags.map(tag => {
                        const tagLowerCase = tag.toLowerCase();
                        const IndicatedTag = tagLocales[tagLowerCase];
                        return (
                          <span key={tag} className="tags">
                            <Link to={`/tags/${tagLowerCase}/`}>
                              #{IndicatedTag}
                            </Link>
                          </span>
                        );
                      })}
                  </li>
                );
              })}
            </ul>
          </div>
        </Section>
      </Layout>
    </Body>
  );
};

export default Articles;
